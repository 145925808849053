import * as http from '@/utils/http';
import { getWeComOAuthURI } from './auth';
import { Toast } from 'vant';

async function handleResponse(response) {
  const data = response.data;
  if (data.error_code !== undefined && data.error_code !== '0') {
    Toast.fail({
      message: data.error_message,
    });
    return Promise.reject(data);
  }

  return Promise.resolve(
    data.response === undefined ? (data === '' ? {} : data) : data.response
  );
}

async function handleError(error) {
  console.log('~handleError')
  const errResp = error.response;

  if (errResp.status === 401) {
    localStorage.clear();

    const currURL = document.location.href;
    console.log('currURL:'+currURL)
    const res = await getWeComOAuthURI(currURL);
    window.location.href = res.oauth_url;
  } else if (errResp.status === 403) {
    window.location.href = '/403';
  } else if (errResp.status === 404) {
    window.location.href = '/404';
  } else if (errResp.status === 400) {
    Toast.fail({
      message: JSON.stringify(errResp.data.error_data),
    });
  } else if (errResp.status === 500) {
    Toast.fail({
      message: errResp.data,
    });
  } else {
    Toast.fail({
      message: error,
    });
  }

  return Promise.reject(errResp);
}

async function _clientRequest(method, url, data) {
  try {
    const resp = await http.request(method, url, data);
    return handleResponse(resp);
  } catch (error) {
    return handleError(error);
  }
}

export const request = {
  get: (url, data) => _clientRequest(http.REQ_GET, url, data),
  post: (url, data) => _clientRequest(http.REQ_POST, url, data),
  put: (url, data) => _clientRequest(http.REQ_PUT, url, data),
  delete: (url, data) => _clientRequest(http.REQ_DELETE, url, data),
};
