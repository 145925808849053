<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {}
};
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  position: relative;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  height: 100%;
  color: #333;
  background-color: #fff;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: PingFangSC-Regular, PingFang SC;
  background: #f4f5f9;
  /*overflow-y: hidden;*/
  height: 100%;
}
</style>
