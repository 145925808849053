import { API_HOST } from '@/configs';

const wecomApi = {
  getWeComOAuthURI: '/manage/v1/users/wecom_oauth_url/',
  getWeComJSSign: '/manage/v1/wecom/jsapi_sign/',
  getWeComAgentJSSign: '/manage/v1/wecom/agent_jsapi_sign/',
};

const wecomSessionApi = {
  getWeComSession: '/manage/v1/wecom_sessions/',
  getWeComSessionDetail: '/manage/v1/wecom_sessions/{pk}/',
  bindTiktokStar: '/manage/v1/wecom_sessions/{pk}/bind_tiktok_star/',
  bindMcn: '/manage/v1/wecom_sessions/{pk}/bind_mcn/',
  bounds: '/manage/v1/wecom_sessions/{pk}/bounds/',
  unbind: '/manage/v1/wecom_sessions/{pk}/unbind/',
  mcnBound: '/manage/v1/wecom_sessions/mcn_bound/',
  tiktokStarBound: '/manage/v1/wecom_sessions/tiktok_star_bound/',
};

const starApi = {
  queryTiktokStar: '/manage/v1/tiktok_star/',
  getTiktokStarDetail: '/manage/v1/tiktok_star/{pk}/',
  updateTiktokStarBusinessInfo: '/manage/v1/tiktok_stars/business_info/{pk}/',
  queryMcn: '/manage/v1/mcns/',
  getMcnDetail: '/manage/v1/mcns/{pk}/',
  updateMcn: '/manage/v1/mcns/{pk}/',
};

const userApi = {
  getLoginUser: '/manage/v1/users/me/'
}

let _apiURLs = {
  ...wecomApi,
  ...wecomSessionApi,
  ...starApi,
  ...userApi,
};

Object.keys(_apiURLs).map((k) => {
  _apiURLs[k] = API_HOST + _apiURLs[k];
});

export const apiURLs = _apiURLs;
