import * as api from '@/api/user';
import * as types from './mutation-types';

const state = {
  loading: false,
  user: undefined,
};

const mutations = {
  [types.SetLoading](state, loading) {
    state.loading = loading;
  },
  [types.SetLoginUser](state, user) {
    state.user = user;
  },
};

const actions = {
  async getLoginUser({ commit }) {
    commit(types.SetLoading, true);
    try {
      const res = await api.getLoginUser();
      commit(types.SetLoginUser, res);
      return res;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      commit(types.SetLoading, false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
