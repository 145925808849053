import { apiURLs } from './urls';
import { request } from './http';

export async function queryTiktokStar(nickname = undefined, page = 1, size = 20) {
  return request.get(apiURLs.queryTiktokStar, {
    nickname,
    page,
    size
  });
}

export async function getTiktokStarDetail(id) {
  return request.get(apiURLs.getTiktokStarDetail, {
    pathParams: { '{pk}': id },
  });
}

export async function updateTiktokStarBusinessInfo(
  id, commissionRate, videoKeepDuration, authorizeVideo,
  authorizeVideoFee, supportSecondaryCreation, secondaryCreationFee,
  authorizedMarketingBrand, supportPinComment, hasFreeDistributionPlatform,
  supportTopic, supportUsePicOrBgm, supportLink, linkFee,
  supportShoppingCart, shoppingCartFee, shoppingCartCommissionRate, remark
) {
  return request.put(apiURLs.updateTiktokStarBusinessInfo, {
    pathParams: { '{pk}': id },
    commission_rate: commissionRate,
    video_keep_duration: videoKeepDuration,
    authorize_video: authorizeVideo,
    authorize_video_fee: authorizeVideoFee,
    support_secondary_creation: supportSecondaryCreation,
    secondary_creation_fee: secondaryCreationFee,
    authorized_marketing_brand: authorizedMarketingBrand,
    support_pin_comment: supportPinComment,
    has_free_distribution_platform: hasFreeDistributionPlatform,
    support_topic: supportTopic,
    support_use_pic_or_bgm: supportUsePicOrBgm,
    support_link: supportLink,
    link_fee: linkFee,
    support_shopping_cart: supportShoppingCart,
    shopping_cart_fee: shoppingCartFee,
    shopping_cart_commission_rate: shoppingCartCommissionRate,
    remark: remark
  });
}

export async function queryMcn(name = undefined, page = 1, size = 20) {
  return request.get(apiURLs.queryMcn, {
    name,
    page,
    size
  });
}

export async function getMcnDetail(id) {
  return request.get(apiURLs.getMcnDetail, {
    pathParams: { '{pk}': id },
  });
}

export async function updateMcn(
  id, rebateRate, rebateGradients, specialPolicy,
  hasRebateTax, crossEntropy, signYearFrame,
  rebateSettlementPeriod, rebateSettlementPayment,
  remark
) {
  return request.put(apiURLs.updateMcn, {
    pathParams: { '{pk}': id },
    rebate_rate: rebateRate,
    rebate_gradients: rebateGradients,
    special_policy: specialPolicy,
    has_rebate_tax: hasRebateTax,
    cross_entropy: crossEntropy,
    sign_year_frame: signYearFrame,
    rebate_settlement_period: rebateSettlementPeriod,
    rebate_settlement_payment: rebateSettlementPayment,
    remark: remark,
  });
}