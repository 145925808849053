let API_HOST = 'https://xapi.yyyad.com';
let CLIENT_HOST = 'https://qywx.yyyad.com';
let DEBUG_WECOM_JS = false;

if (process.env.NODE_ENV === 'production') {
  CLIENT_HOST = 'https://qywx.yyyad.com';
  DEBUG_WECOM_JS = false;
}


const REQUIRED_JS_API_LIST = [
  'getContext',
  'openEnterpriseChat',
  'getCurExternalContact',
  'getCurExternalChat'
];

export { API_HOST, CLIENT_HOST, REQUIRED_JS_API_LIST, DEBUG_WECOM_JS };
