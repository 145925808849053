import {createRouter, createWebHistory} from 'vue-router';
import Index from '../views/Index.vue';
import home from './home';
import star from './star';
import mcn from './mcn';
import establish from './establish';
import qs from 'qs';
// import { wxReady } from '@utils/wework-sdk'
import store from '@/store';

let ENTRY_PAGE_URL = null;

export const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
        children: [
            home,
            establish,
            star,
            mcn,
            {
                path: '403',
                name: 'NoPermission',
                component: () => import('/src/views/errors/NoPermission'),
            },
            {
                path: '404',
                name: 'NotFound',
                component: () => import('/src/views/errors/NotFound'),
            },
        ],
    },
];

export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

const NO_AUTH_ROUTES = ['NoPermission', 'NotFound']
router.beforeEach((to, from, next) => {
    if (to.meta?.title) {
        //判断是否有标题
        document.title = to.meta.title;
    }

    // 页面无需鉴权，直接放行
    if (NO_AUTH_ROUTES.includes(to.name)) {
        return next()
    }

    next();
});


// SDK签名校验
router.beforeResolve((to, from, next) => {
    const queryString = qs.stringify(to.query)

    const params = {
        url: encodeURI(
          window.location.origin + (to.path || '/') + (queryString ? '?' + queryString : '')
        ),
    }
    console.log('验证的url: ' + params.url)
    console.log('当前的url: ' + window.location.href)
    // store.dispatch('auth/sign', params).catch((err) => {
    //     if (err.response.status === 401) {
    //         const timer = setTimeout(() => {
    //             Toast.loading({
    //                 message: '登录中...',
    //                 forbidClick: true,
    //                 loadingType: 'spinner',
    //                 duration: 0,
    //             })
    //         }, 2000)
    //
    //         // 请求oauth链接授权登录
    //         const nextUrl = window.location.origin + (to.path || '/')
    //         store
    //           .dispatch('auth/login', {
    //               next_url: nextUrl,
    //           })
    //           .catch((err) => {
    //               next({
    //                   name: 'Error',
    //                   query: {
    //                       tip: err?.message || '登录失败',
    //                       redirect: encodeURI(window.location.href),
    //                   },
    //               })
    //           })
    //           .finally(() => {
    //               timer && clearTimeout(timer)
    //               Toast.clear()
    //           })
    //         return
    //     }
    //     typeof err === 'object' &&
    //     Vue.prototype.$message.error(
    //       err?.errMsg || '获取config签名失败，请尝试重新进入:' + err.status
    //     )
    // })
    //
    // const configStatus = wxReady()
    //
    // // 页面API需要wx.agentConfig鉴权
    // if (to.meta?.needAgentConfig) {
    //     configStatus
    //       .then(() => {
    //           return store.dispatch('auth/agentSign', params)
    //       })
    //       .then(() => {
    //           return next()
    //       })
    //       .catch((err) => {
    //           typeof err === 'object' &&
    //           Vue.prototype.$message.error(err?.errMsg || '获取agentConfig签名失败，请尝试重新进入')
    //       })
    // } else {
    //     configStatus.then(() => next())
    // }
    return next()
})


router.afterEach((to, from) => {
    if (
      (window.history.state.position === 0 ||
        window.history.state.back === null) &&
      !ENTRY_PAGE_URL
    ) {
        ENTRY_PAGE_URL = to.fullPath.split('#')[0];
    }
    if (window.history.state.replaced) {
        ENTRY_PAGE_URL = to.fullPath.split('#')[0];
    }

    if (window.__wxjs_is_wkwebview) {
        if (!store.state.wechat.configured) {
            store.dispatch('wecom/initConfig', { path: ENTRY_PAGE_URL });
        }
    } else {
        store.dispatch('wecom/initConfig', { path: to.fullPath });
    }
});
