export function convertUnit(num, divisor=10000, fixedLength = 1) {
  if(!num){
    return 0
  }

  const value = (num / divisor).toFixed(fixedLength);
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}


export function toYuan(amount, fixedLength=0) {
  if(!amount){
    return 0
  }

  const price = parseFloat((amount / 100).toFixed(fixedLength))
  return String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}


export function queryURLParamsRegEs6(url) {
  let obj = {}
  let reg = /([^?=&]+)=([^?=&]+)/g
  url.replace(reg, (...arg) => {
    obj[arg[1]] = arg[2]
  })
  return obj
}