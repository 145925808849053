export const SetLoading = 'SetLoading';
export const SetWeComJS = 'SetWeComJS';
export const SetWeComExternalUserId = 'SetWeComExternalUserId';
export const SetWeComExternalGroupChatId = 'SetWeComExternalGroupChatId';
export const SetSession = 'SetSession';
export const SetBounds = 'SetBounds';
export const SetStars = 'SetStars';
export const SetCurrentStar = 'SetCurrentStar';
export const SetCurrentMcn = 'SetCurrentMcn';
export const SetLoginUser = 'SetLoginUser';
