import { createStore } from 'vuex';
import wecom from './wecom';
import star from './star';
import session from './session';
import user from './user';

export default createStore({
  modules: {
    wecom,
    star,
    session,
    user,
  },
});
