import * as api from '@/api/wecom';
import * as configs from '@/configs';
import * as types from './mutation-types';

const state = {
  configured: false,
  wecomEntry: '',
  wecomShareTicket: '',
  externalUserId: '',
  externalGroupChatId: '',
  session: undefined,
};

const mutations = {
  [types.SetWeComJS](state, wx) {
    state.configured = true;
  },
  [types.SetWeComExternalUserId](state, userId) {
    console.log('SetWeComExternalUserId:' + userId);
    state.externalUserId = userId;
  },
  [types.SetWeComExternalGroupChatId](state, chatId) {
    state.externalGroupChatId = chatId;
  },
};

const actions = {
  async initConfig({ commit }, { path }) {
    try {
      let res = await api.getWeComJSSign(path);
      // eslint-disable-next-line no-undef
      wx.config({
        debug: configs.DEBUG_WECOM_JS,
        beta: true,
        jsApiList: configs.REQUIRED_JS_API_LIST,
        ...res,
      });

      res = await api.getWeComAgentJSSign(path);

      // eslint-disable-next-line no-undef
      wx.agentConfig({
        jsApiList: configs.REQUIRED_JS_API_LIST,
        ...res,
        success: function(res) {
          console.log(res)
          // eslint-disable-next-line no-undef
          commit(types.SetWeComJS, wx);
        },
        fail: function(res) {
          console.log(res)
          if(res.errMsg.indexOf('function not exist') > -1){
            alert('版本过低请升级')
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  },
  async getExternalCustomerUserIdOrGroupChatId({ commit }) {
    try {
      // eslint-disable-next-line no-undef
      wx.invoke('getContext', {}, function (res) {
        console.log(res);
        if (res.err_msg === 'getContext:ok') {
          const entry = res.entry; //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment
          if (entry === 'single_chat_tools') {
            // eslint-disable-next-line no-undef
            wx.invoke('getCurExternalContact', {
            }, function(res){
              console.log(res);
              if (res.err_msg === 'getCurExternalContact:ok') {
                const userId  = res.userId ; //返回当前外部联系人userId
                console.log('userId:' + userId);
                commit(types.SetWeComExternalUserId, res.userId);
              } else {
                //错误处理
              }
            });
          } else if (entry === 'group_chat_tools') {
            // eslint-disable-next-line no-undef
            wx.invoke('getCurExternalChat', {}, function (res) {
              console.log(res);
              if (res.err_msg === 'getCurExternalChat:ok') {
                // chatId  = res.chatId ; //返回当前客户群的群聊ID
                commit(types.SetWeComExternalGroupChatId, res.chatId);
              } else {
                //错误处理
              }
            });
          }
        } else {
          //错误处理
        }
      });
    } catch (err) {
      console.log(err);
    }
  },
  async openExternalCustomerChat({ commit }, { userId }) {
    // eslint-disable-next-line no-undef
    wx.openEnterpriseChat({
      userIds: '',    //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
      externalUserIds: userId, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
      groupName: '',  // 会话名称。单聊时该参数传入空字符串""即可。
      chatId: '', // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
      success: function(res) {
        console.log(res)
      },
      fail: function(res) {
        if(res.errMsg.indexOf('function not exist') > -1){
          alert('版本过低请升级')
        }
      }
    });
  },
  async openExternalGroupChat({ commit }, { chatId }) {
    // eslint-disable-next-line no-undef
    wx.openEnterpriseChat({
      userIds: '',    //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
      externalUserIds: '', // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
      groupName: '',  // 会话名称。单聊时该参数传入空字符串""即可。
      chatId: chatId, // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
      success: function(res) {
        console.log(res)
      },
      fail: function(res) {
        if(res.errMsg.indexOf('function not exist') > -1){
          alert('版本过低请升级')
        }
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
