export default {
  path: '',
  name: 'Home',
  redirect: '/star-list',
  meta: {
    title: '友友投放应用主页',
    keepAlive: true
  },
  component: () => import('/src/views/Home'),
  children: [
    {
      path: 'star-list',
      name: 'StarList',
      meta: {
        keepAlive: true
      },
      component: () => import('/src/views/star/List'),
    },
    {
      path: 'mcn-list',
      name: 'McnList',
      component: () => import('/src/views/mcn/List'),
    },
    {
      path: 'mine',
      name: 'mine',
      component: () => import('/src/views/mine/Index'),
    },
  ],
};
