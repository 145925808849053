import { createApp } from 'vue';
import App from './App.vue';
import 'vant/lib/index.css';
import '@vant/touch-emulator';
// import 'vant/lib/icon/local.css';

import { router } from './router';
import store from './store';
import { toYuan, convertUnit } from '@/utils/convert';

import {
  ActionSheet,
  Button,
  Cell,
  Checkbox,
  Dialog,
  Icon,
  Field,
  List,
  Loading,
  PullRefresh,
  Overlay,
  Radio,
  RadioGroup,
  Sticky,
  Tabbar,
  TabbarItem,
  Toast,
} from 'vant';

const app = createApp(App);

app.config.globalProperties.$toYuan = toYuan;
app.config.globalProperties.$convertUnit = convertUnit;

app.use(router)
  .use(ActionSheet)
  .use(Button)
  .use(Cell)
  .use(Checkbox)
  .use(Dialog)
  .use(Icon)
  .use(Field)
  .use(List)
  .use(Loading)
  .use(PullRefresh)
  .use(Overlay)
  .use(Radio)
  .use(RadioGroup)
  .use(Sticky)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Toast)
  .use(store)
  .mount('#app');
