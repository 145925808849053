import * as api from '@/api/star';
import * as types from './mutation-types';

const state = {
  loading: false,
  stars: [],
  currentStar: undefined,
  currentMcn: undefined,
};

const mutations = {
  [types.SetLoading](state, loading) {
    state.loading = loading;
  },
  [types.SetStars](state, res) {

    if (res.page.current_page === -1) {
      state.stars = [];
    }
    res.data.forEach((x) => {
      state.stars.push(x);
    });
  },
  [types.SetCurrentStar](state, star) {
    state.currentStar = star;
  },
  [types.SetCurrentMcn](state, mcn) {
    state.currentMcn = mcn;
  },
};

const actions = {
  async queryTiktokStar({ commit }, { nickname = undefined, page = 1, size = 20 }) {
    commit(types.SetLoading, true);
    try {
      const res = await api.queryTiktokStar(nickname, page, size);
      commit(types.SetStars, res);
      return res;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      commit(types.SetLoading, false);
    }
  },
  async getTiktokStarDetail({ commit }, { id }) {
    commit(types.SetLoading, true);
    try {
      return await api.getTiktokStarDetail(id);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      commit(types.SetLoading, false);
    }
  },
  async updateTiktokStarBusinessInfo(
    { commit },
    {
      id, commissionRate, videoKeepDuration, authorizeVideo,
      authorizeVideoFee, supportSecondaryCreation, secondaryCreationFee,
      authorizedMarketingBrand, supportPinComment, hasFreeDistributionPlatform,
      supportTopic, supportUsePicOrBgm, supportLink, linkFee,
      supportShoppingCart, shoppingCartFee, shoppingCartCommissionRate, remark
    }) {
    commit(types.SetLoading, true);
    try {
      return await api.updateTiktokStarBusinessInfo(
        id, commissionRate, videoKeepDuration, authorizeVideo,
        authorizeVideoFee, supportSecondaryCreation, secondaryCreationFee,
        authorizedMarketingBrand, supportPinComment, hasFreeDistributionPlatform,
        supportTopic, supportUsePicOrBgm, supportLink, linkFee,
        supportShoppingCart, shoppingCartFee, shoppingCartCommissionRate, remark
      );
    } catch (err) {
      return Promise.reject(err);
    } finally {
      commit(types.SetLoading, false);
    }
  },
  async queryMcn({ commit }, { name = undefined, page = 1, size = 20 }) {
    commit(types.SetLoading, true);
    try {
      const res = await api.queryMcn(name, page, size);
      commit(types.SetStars, res);
      return res;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      commit(types.SetLoading, false);
    }
  },
  async getMcnDetail({ commit }, { id }) {
    commit(types.SetLoading, true);
    try {
      return await api.getMcnDetail(id);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      commit(types.SetLoading, false);
    }
  },
  async updateMcn(
    { commit },
    {
      id, rebateRate, rebateGradients, specialPolicy,
      hasRebateTax, crossEntropy, signYearFrame,
      rebateSettlementPeriod, rebateSettlementPayment,
      remark
    }) {
    commit(types.SetLoading, true);
    try {
      return await api.updateMcn(
        id, rebateRate, rebateGradients, specialPolicy,
        hasRebateTax, crossEntropy, signYearFrame,
        rebateSettlementPeriod, rebateSettlementPayment,
        remark
      );
    } catch (err) {
      return Promise.reject(err);
    } finally {
      commit(types.SetLoading, false);
    }
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
