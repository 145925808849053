export default {
  path: 'mcn',
  name: 'Mcn',
  meta: {
    title: '友友投放应用主页',
  },
  component: () => import('/src/views/mcn/Index'),
  children: [
    {
      path: ':id',
      name: 'McnDetail',
      component: () => import('/src/views/mcn/Detail'),
    },
  ],
};
