export default {
    path: 'establish',
    name: 'Establish',
    meta: {
        title: '友友投放应用主页',
    },
    component: () => import('/src/views/Index'),
    children: [
        {
            path: '',
            name: 'EstablishMain',
            component: () => import('/src/views/establish/Establish'),
        },
        {
            path: '',
            name: 'EstablishMain',
            component: () => import('/src/views/establish/Establish'),
        },
        {
            path: 'bounds',
            name: 'EstablishBounds',
            component: () => import('/src/views/establish/Bounds'),
        },
        {
            path: 'unbound',
            name: 'EstablishUnbound',
            component: () => import('/src/views/establish/Unbound'),
        },
        {
            path: ':id/binging-star',
            name: 'BindingSessionStar',
            component: () => import('/src/views/establish/BingingStar'),
        },
        {
            path: ':id/binging-mcn',
            name: 'BindingSessionMcn',
            component: () => import('/src/views/establish/BingingMcn'),
        },
        {
            path: 'star/:id/edit-business-info',
            name: 'EditBusinessInfo',
            component: () => import('/src/views/establish/EditBusinessInfo'),
        },
        {
            path: 'star/:id/edit-mcn-business-info',
            name: 'EditMcnBusinessInfo',
            component: () => import('/src/views/establish/EditMcnBusinessInfo'),
        },
    ],
};
