import * as api from '@/api/session';
import * as configs from '@/configs';
import * as types from './mutation-types';

const state = {
  loading: false,
  session: undefined,
  bound_stars: [],
  bound_mcns: []
};

const mutations = {
  [types.SetLoading](state, loading) {
    state.loading = loading;
  },
  [types.SetSession](state, session) {
    state.session = session;
  },
  [types.SetBounds](state, bounds) {
    state.bound_stars = bounds.stars;
    state.bound_mcns = bounds.mcns;
  },
};

const actions = {
  async getWeComSession({ commit }, { type, externalUserId = undefined, externalGroupChatId = undefined }) {
    commit(types.SetLoading, true);
    try {
      const res = await api.getWeComSession(type, externalUserId, externalGroupChatId);
      commit(types.SetSession, res);
      return res;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      commit(types.SetLoading, false);
    }
  },
  async getWeComSessionBounds({ commit }, { sessionId }) {
    commit(types.SetLoading, true);
    try {
      const res = await api.getWeComSessionBounds(sessionId);
      commit(types.SetBounds, res);
      return res;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      commit(types.SetLoading, false);
    }
  },
  async getWeComSessionDetail({ commit }, { id }) {
    commit(types.SetLoading, true);
    try {
      const res = await api.getWeComSessionDetail(id);
      commit(types.SetSession, res);
      return res;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      commit(types.SetLoading, false);
    }
  },
  async bindTiktokStar({ commit }, { id, starId }) {
    try {
      return await api.bindTiktokStar(id, starId);
    } catch (err) {
      return Promise.reject(err);
    } finally {
    }
  },
  async bindMcn({ commit }, { id, mcnId }) {
    try {
      return await api.bindMcn(id, mcnId);
    } catch (err) {
      return Promise.reject(err);
    } finally {
    }
  },
  async unbind({ commit }, { id, mcnsId, starsId }) {
    console.log('session unbind')
    try {
      return await api.unbind(id, mcnsId, starsId);
    } catch (err) {
      return Promise.reject(err);
    } finally {
    }
  },
  async getTiktokStarBound({ commit }, { starId }) {
    commit(types.SetLoading, true);
    try {
      return await api.getTiktokStarBound(starId);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      commit(types.SetLoading, false);
    }
  },
  async getMcnBound({ commit }, { mcnId }) {
    commit(types.SetLoading, true);
    try {
      return await api.getMcnBound(mcnId);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      commit(types.SetLoading, false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
