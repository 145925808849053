import axios from 'axios';

export const REQ_GET = 'get';
export const REQ_POST = 'post';
export const REQ_PUT = 'put';
export const REQ_DELETE = 'delete';
export const REQ_HEAD = 'head';
export const REQ_OPTIONS = 'options';

export const DEFAULT_RESPONSE_TYPE = 'json';

const NO_PAYLOAD_METHODS = [REQ_HEAD, REQ_OPTIONS];

function handleUrl(url, data = {}) {
  const pathParams = data.pathParams ? data.pathParams : null;
  if (pathParams) {
    for (let key in pathParams) {
      url = url.replace(key, pathParams[key]);
    }
    delete data.pathParams;
  } else if (/{.+}/.test(url)) {
    Object.keys(data).forEach((x) => {
      const str = url.replace(`{${x}}`, data[x]);
      if (url !== str) {
        url = str;
        delete data[x];
      }
    });
  }
  return url;
}

export async function request(method, url, data) {
  url = handleUrl(url, data);

  let reqConfig = {
    method: method,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    withCredentials: true,
    url: url,
    responseType: DEFAULT_RESPONSE_TYPE,
  };
  if (data && NO_PAYLOAD_METHODS.indexOf(method) === -1) {
    if (method === REQ_GET) {
      reqConfig.params = data;
    } else {
      reqConfig.data = JSON.stringify(data);
    }
  }

  return await axios.request(reqConfig);
}
