import { apiURLs } from './urls';
import { request } from './http';

export async function getWeComSession(type, externalUserId = undefined, externalGroupChatId = undefined) {
  return request.get(apiURLs.getWeComSession, {
    type: type,
    external_user_id: externalUserId,
    group_chat_id: externalGroupChatId,
  });
}

export async function getWeComSessionBounds(sessionId) {
  return request.get(apiURLs.bounds, {
    pathParams: { '{pk}': sessionId },
  });
}

export async function getWeComSessionDetail(id) {
  return request.get(apiURLs.getWeComSessionDetail, {
    pathParams: { '{pk}': id },
  });
}

export async function bindTiktokStar(id, starId) {
  return request.post(apiURLs.bindTiktokStar, {
    pathParams: { '{pk}': id },
    star_id: starId
  });
}

export async function bindMcn(id, mcnId) {
  return request.post(apiURLs.bindMcn, {
    pathParams: { '{pk}': id },
    mcn_id: mcnId
  });
}

export async function unbind(id, mcnsId, starsId) {
  return request.post(apiURLs.unbind, {
    pathParams: { '{pk}': id },
    stars_id: starsId,
    mcns_id: mcnsId,
  });
}

export async function getTiktokStarBound(starId) {
  return request.get(apiURLs.tiktokStarBound, {
    star_id: starId,
  });
}

export async function getMcnBound(mcnId) {
  return request.get(apiURLs.mcnBound, {
    mcn_id: mcnId,
  });
}