import { apiURLs } from './urls';
import { request } from './http';
import { CLIENT_HOST } from '@/configs';

export async function getWeComJSSign(path) {
  return request.post(apiURLs.getWeComJSSign, {
    url: CLIENT_HOST + path,
  });
}


export async function getWeComAgentJSSign(path) {
  return request.post(apiURLs.getWeComAgentJSSign, {
    url: CLIENT_HOST + path,
  });
}
